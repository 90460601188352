import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { text } from '../../utils/colors'
import { fontRegular } from '../../utils/fontStyles'
import media from '../../utils/mediaQueries'

const { se, desktop } = media

const P = ({ children, align, className }) => (
  <StyledP align={align} className={className}>
    {children}
  </StyledP>
)

P.defaultProps = {
  align: 'left',
  className: '',
}

P.propTypes = {
  children: PropTypes.string.isRequired,
  align: PropTypes.string,
  className: PropTypes.string,
}

export default P

const StyledP = styled.p`
  ${fontRegular()}
  display: inline-block;
  font-size: 20px;
  line-height: 1.2;
  color: ${text};
  margin-bottom: 30px;
  ${se &&
    se`
    text-align: left;
  `}

  ${desktop &&
    desktop`
    text-align: ${({ align }) => align && align};
  `}
`
