import React from 'react'
import styled from 'styled-components'
import { SocialIcon } from 'react-social-icons'

import media from '../../utils/mediaQueries'

const { se, desktop } = media

const Profiles = [
  {
    link: 'https://www.facebook.com/mindmemobile/',
  },
  {
    link: 'https://twitter.com/mindmemobile',
  },
  {
    link: 'https://www.linkedin.com/company/mindme-mobile/',
  },
]

const FooterSocialMedia = () => (
  <SocialMediaWrapper>
    <SocialMediaContainer>
      {Profiles.map(({ link }) => (
        <StyledSocialIcon
          url={link}
          bgColor="#ff5900"
          key={link}
          style={{ height: 40, width: 40 }}
        />
      ))}
    </SocialMediaContainer>
  </SocialMediaWrapper>
)

export default FooterSocialMedia

const SocialMediaWrapper = styled.div`
  max-width: 150px;
  flex: 1;

  ${se &&
    se`
    width: 200px;
    margin: auto;
  `}

  ${desktop &&
    desktop`
    padding: 0;
    margin: 0;
  `}
`

const SocialMediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledSocialIcon = styled(SocialIcon)`
 
`
