export const fontRegular = () => `
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
`

export const fontSemiBold = () => `
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
`

export const fontBold = () => `
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
`

export const fontExtraBold = () => `
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
`
