import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import FooterSocialMedia from "./footersocialmedia"

import Logo from "../../images/powered-by-mindme.svg"

import { offWhite } from "../../utils/colors"
import media from "../../utils/mediaQueries"
import P from "../typography/P"

const { se, tablet, desktop } = media

const date = new Date()
const currentYear = date.getFullYear()

const copyWriteText = `© ${currentYear} MindMe, Inc. All rights reserved.`

const legalLinks = [
  {
    name: "support@mindmemobile.com",
    url: "mailto:support@mindmemobile.com",
  },
]

const helpLinks = [
  {
    name: "Knowledge Base",
    url: "https://knowledgebase.mindmemobile.com/",
  },
]

const supportLinks = [
  {
    name: "Free Account",
    url: "/beta",
  },
]

const Footer = () => (
  <FooterWrapper>
    <FooterRow>
      <LogoContainer>
        <LogoWrapper>
          <Link to="/">
            <img src={Logo} alt="MindMe Logo" />
          </Link>
        </LogoWrapper>
      </LogoContainer>
      <MenuWrapper>
        <LinksContainer>
          {helpLinks.map(link => {
            const { name, url } = link
            return (
              <LinkWrapper key={name + url}>
                <FooterOffLink href={url} target="_blank">
                  <P>{name}</P>
                </FooterOffLink>
              </LinkWrapper>
            )
          })}
        </LinksContainer>
        <LinksContainer>
          {supportLinks.map(link => {
            const { name, url } = link
            return (
              <LinkWrapper key={name + url}>
                <FooterLink to={url}>
                  <P>{name}</P>
                </FooterLink>
              </LinkWrapper>
            )
          })}
        </LinksContainer>
        <LinksContainer>
          {legalLinks.map(link => {
            const { name, url } = link
            return (
              <LinkWrapper key={name + url}>
                <FooterOffLink href={url} target="_blank">
                  <P>{name}</P>
                </FooterOffLink>
              </LinkWrapper>
            )
          })}
        </LinksContainer>
      </MenuWrapper>
    </FooterRow>
    <BottomBar>
      <BottomBarContainer>
        <CopyWrite>{copyWriteText}</CopyWrite>
        <FooterSocialMedia />
      </BottomBarContainer>
    </BottomBar>
  </FooterWrapper>
)

export default Footer

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 80px;
  ${desktop &&
    desktop`
  width: 100%;
  `}
`

const FooterRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  ${se &&
    se`
   flex-direction: column;
   margin-bottom: 20px;
  `}

  ${desktop &&
    desktop`
    flex-direction: row;
    align-items: flex-start;
    max-width: 1200px;
    margin: auto;
  `}
`

const CopyWrite = styled(P)`
  ${se &&
    se`
    margin: 0 auto 20px auto;
    padding: 0 20px;
    text-align: center;
  `}
  ${desktop &&
    desktop`
  margin: 0;
  text-align: left;
  `}
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${se &&
    se`
    width: 100%;
    padding: 0 25px;
  `}

  ${desktop &&
    desktop`
  flex: 2;
`}
`

const MenuWrapper = styled.div`
  display: flex;

  ${se &&
    se`
    width: 100%;
    margin: auto;
    flex-direction: column;
  `}

  ${tablet &&
    tablet`
    width: 80%;
    margin: auto;
    flex-direction: row;
  `}

  ${desktop &&
    desktop`
  flex: 3;
`}
`

const LinksContainer = styled.ul`
  list-style: none;
  padding: 0;
  ${se &&
    se`
    order: 2;
    display: flex;
    flex-direction: column;
    width: 320px;
    align-items: flex-start;
    margin: 0 0 30px 0;
    width: 100%;
    padding: 0 25px;
  `}

${tablet &&
  tablet`
    align-items: center;
  `}

  ${desktop &&
    desktop`
    display: flex;
    flex: 1;
    order: 3;
    padding: 0;
    width: auto;
    align-items: flex-start;
    margin: 0 0 60px 0;
  `}
`

const LinkWrapper = styled.li`
  list-style-type: none;

  ${se &&
    se`
  margin-bottom: 1rem;
  `}

  ${desktop &&
    desktop`
    margin-right: 1.3rem;
    margin-bottom: 2rem; 
  `}
`

const FooterLink = styled(Link)`
  text-decoration: none;
  width: auto;
  text-transform: none;
  ${se &&
    se`
    text-align: center;
    
  `}

  ${desktop &&
    desktop`
   
  `}

  &:hover {
    text-decoration: underline;
  }
`
const FooterOffLink = styled.a`
  text-decoration: none;
  width: auto;
  text-transform: none;
  ${se &&
    se`
    text-align: center;
    
  `}

  ${desktop &&
    desktop`
   
  `}

  &:hover {
    text-decoration: underline;
  }
`

const LogoWrapper = styled.div`
  width: 200px;

  img {
    width: 100%;
  }

  ${desktop &&
    desktop`
margin: 0;
`}
`

const BottomBar = styled.div`
  background-color: ${offWhite};

  ${se &&
    se`
    padding: 30px 0;
  `}

  ${desktop &&
    desktop`
    padding: 0;
  `}
`

const BottomBarContainer = styled.div`
  ${se &&
    se`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}

  ${desktop &&
    desktop`
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    align-items: center;
    height: 80px;
    flex-direction: row;
  `}
`
