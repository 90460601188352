import React from "react"
import styled from "styled-components"

import { white } from "../../utils/colors"
import media from "../../utils/mediaQueries"

const { se, desktop } = media

const MobileMenuToggle = React.forwardRef((props, ref) => {
  const { isClicked, toggle } = props

  return (
    <StyledButtonWrapper onClick={toggle}>
      <StyledButton ref={ref} isClicked={isClicked}>
        <Line isClicked={isClicked} />
      </StyledButton>
    </StyledButtonWrapper>
  )
})

MobileMenuToggle.propTypes = {}

export default MobileMenuToggle

const StyledButtonWrapper = styled.div`
  ${se &&
    se`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: ${props => props.theme.accent};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);

  display:none;
  `}

  ${desktop &&
    desktop`
  display:none;
`}
`

const StyledButton = styled.button`
  ${se &&
    se`
    background: none;
    border: 0;
    display: block;
    padding: 0;
    position: relative;
    z-index: 60;
    cursor: pointer;
    opacity: 0.65;
   
    &:hover {
      opacity: 1;
      div,
      div::before,
      div::after {
        width: 24px;
      }
    }
  
  `}

  ${desktop &&
    desktop`
    display: none;
  `}
`

const Line = styled.div`
  width: 23px;
  height: 3px;
  margin: 10px 0;
  border-radius: 4px;
  background: ${({ isClicked }) => (isClicked ? "0 0;" : `${white}`)};
  position: relative;
  z-index: 1000;
  transition: all 250ms cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &::before {
    top: 7px;
    width: 16px;
    right: 0;
    border-radius: 10px;
    height: 3px;
    background: ${white};
    content: " ";
    position: absolute;
    transition: all 350ms cubic-bezier(0.68, -0.55, 0.265, 1.55);

    ${({ isClicked }) =>
      isClicked &&
      `
      top: 0;
      width: 24px;
      transform: rotate(45deg);
    `}
  }

  &::after {
    top: -7px;
    width: 22px;
    right: 0;
    border-radius: 1px;
    height: 3px;
    background: ${white};
    content: " ";
    position: absolute;
    transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);

    ${({ isClicked }) =>
      isClicked &&
      `
      top: 0;
      width: 24px;
      transform: rotate(-45deg);
    `}
  }
`
