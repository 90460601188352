import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import { white, orange } from "../../utils/colors"
import { fontBold } from "../../utils/fontStyles"
import { buttonShadow, buttonHoverShadow } from "../../utils/shadows"
import media from "../../utils/mediaQueries"

const { se, desktop } = media

const InternalButton = ({ title, link, className, buttonGreen }) => (
  <StyledLink to={link} className={className} buttonGreen={buttonGreen}>
    {title}
  </StyledLink>
)

InternalButton.defaultProps = {
  className: "",
  buttonGreen: false,
}

InternalButton.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  className: PropTypes.string,
  buttonGreen: PropTypes.bool,
}

export default InternalButton

const StyledLink = styled(Link)`
  ${fontBold()}
  ${buttonShadow()}

  display: inline-block;
  color: ${white};
  font-weight: 400;
  text-align: center;
  text-decoration: none;
 
  min-height: 60px;
  border-radius: 8px;
  padding: 15px 20px;
  background-color: ${orange};
  transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &:hover {
    ${buttonHoverShadow()}
    transform: translateY(-5px) scale(1.05);
    text-decoration: none;
    color: ${white};
  }

  ${se && se`
    font-size: 20px;
  `}

  ${desktop &&
    desktop`
    width: 380px;
    font-size: 25px;
  `}
`
