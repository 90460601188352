import React from "react"
import styled, {  ThemeProvider } from "styled-components"
import useDarkMode from "use-dark-mode"
import useToggle from "../utils/hooks/useToggle"
import Header from "./layout/header"
import media from "../utils/mediaQueries"
import { themeLight, themeDark } from "../utils/themes"
import MobileMenuToggle from "./layout/mobilemenutoggle"
import "../utils/normalize.css"
import Footer from "./layout/footer";

const { se, desktop } = media

const Layout = ({ children }) => {
  const darkMode = useDarkMode(false)

  const [isToggled, toggle] = useToggle(false)

  return (
    <ThemeProvider theme={darkMode.value ? themeDark : themeLight}>
      <>
        <PageWrapper>
          <Header />
          <StyledMain>{children}</StyledMain>
          <Footer />
        </PageWrapper>
      </>
    </ThemeProvider>
  )
}

export default Layout

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  ${desktop &&
    desktop`
  
  `}
`

const StyledMain = styled.main`
  ${se &&
    se`
    
  `}

  ${desktop &&
    desktop`
    margin: auto;
    width: 100%;
  `}
`
