export const navShadow = () => `
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
`

export const textShadow = () => `
  text-shadow: 20px 15px 10px rgba(51, 51, 51, 0.2);
`

export const buttonShadow = () => `
  box-shadow:  20px 15px 20px rgba(65, 26, 5, 0.1);
`

export const buttonHoverShadow = () => `
  box-shadow:  20px 25px 30px rgba(65, 26, 5, 0.1);
`
