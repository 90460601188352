export const white = "#fff"
export const black = "#333"
export const text = "#555"
export const textLight = "#777"
export const superLightGrey = "#fafafa"
export const borderGrey = "#dddddd"
export const charcoal = "#404040"
export const offWhite = "#f5f5f5"
export const smoke = "#eee"
export const darkSmoke = "#222"
export const brandPrimary = "#57ac2d"
export const orange = "#FF5900"
