import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { black } from '../../utils/colors'
import { fontExtraBold } from '../../utils/fontStyles'
import media from '../../utils/mediaQueries'

const { se, desktop } = media

const H1 = ({ children, align, className }) => (
  <StyledH1 align={align} className={className}>{children}</StyledH1>
)

H1.defaultProps = {
  align: 'left',
}

H1.propTypes = {
  children: PropTypes.string.isRequired,
  align: PropTypes.string,
}

export default H1

const StyledH1 = styled.h1`
  ${fontExtraBold()};

  font-size: 48px;
  line-height: 1.2;
  color: ${black};

  ${se &&
    se`
    text-align: left;
  `}

  ${desktop &&
    desktop`
    text-align: ${({ align }) => align && align};
  `}
`
