import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import media from "../../utils/mediaQueries"

import Logo from "../../images/mindmelogo-poweteam.png"
import { charcoal, white } from "../../utils/colors"
import { fontRegular } from "../../utils/fontStyles"
const { se, desktop } = media

const Header = () => (
  <StyledHeader>
    <StyledHeaderContainer>
      <LogoWrapper>
        <Link to="/">
          <StyledImg src={Logo} alt="MindMe Logo" />
        </Link>
      </LogoWrapper>
      <StyledNav>
        <StyledNavList>
          <StyledNavListItem>
            <StyledLink
              href="https://m.mindmemobile.com/login"
              target="_blank"
              accent
            >
              Sign In
            </StyledLink>
          </StyledNavListItem>
        </StyledNavList>
      </StyledNav>
    </StyledHeaderContainer>
  </StyledHeader>
)

export default Header

const StyledHeader = styled.header`
  ${se &&
    se`
     display: block;
  `}
  ${desktop &&
    desktop`
    display: block;
      position: absolute;
      z-index: 40;
      top: 0;
      width: 100%;
`}
`

const StyledHeaderContainer = styled.div`
  ${desktop &&
    desktop`
      position: relative;
      display: flex;
`}
`

const LogoWrapper = styled.div`
  ${se &&
    se`
  width: 100%;
  padding: 10px;
`}

  ${desktop &&
    desktop`
      width: 500px;
      padding: 10px 30px;
`}
`

const StyledImg = styled.img`
  margin-bottom: 0;
  ${se &&
    se`
   width: 80%;
   max-width: 300px;
   margin: auto;
   display: block;
  `}

  ${desktop &&
    desktop`
      width: 100%;
      max-width: 400px;
`}
`

const StyledNav = styled.nav`
  ${se &&
    se`
  display: none;
`}
  ${desktop &&
    desktop`
    display: flex;
   
    flex-grow: 1;

  `}
`

const StyledNavList = styled.ul`
  margin-bottom: 0;
  height: 100%;
  ${desktop &&
    desktop`
    display: flex;
    justify-content: flex-end;
    width: 90%;
  `}
`

const StyledNavListItem = styled.li`
  list-style: none;
  margin-left: 10px;
  margin-right: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 0;
`

const StyledLink = styled.a`
  color: ${charcoal};
  ${fontRegular()}
  text-decoration: none;

  ${({ accent }) =>
    accent
      ? css`
          border: 1px solid ${charcoal};
          padding: 10px 20px;
          border-radius: 8px;
          transition: all 300ms ease;

          &:hover {
            background: ${charcoal};
            color: ${white};
          }
        `
      : ""}
`
