import {
  white,
  black,
  offWhite,
  smoke,
  charcoal,
  darkSmoke,
  brandPrimary,
  orange,
} from "./colors"

export const themeLight = {
  bg: `${white}`,
  text: `${black}`,
  sidebar: `${offWhite}`,
  border: `${smoke}`,
  accent: `${brandPrimary}`,
}

export const themeDark = {
  bg: `${black}`,
  text: `${offWhite}`,
  sidebar: `${charcoal}`,
  border: `${darkSmoke}`,
  accent: `${orange}`,
}
